/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2021 Colophon Foundry
 *
 * Licenced to Elliot Hasse Guest House
 */

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-regular-pro.eot');
  src: url('./mabry-mono/mabry-mono-regular-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-regular-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-regular-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-regular-pro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-italic-pro.eot');
  src: url('./mabry-mono/mabry-mono-italic-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-italic-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-italic-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-italic-pro.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-medium-pro.eot');
  src: url('./mabry-mono/mabry-mono-medium-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-medium-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-medium-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-medium-pro.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-medium-italic-pro.eot');
  src: url('./mabry-mono/mabry-mono-medium-italic-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-medium-italic-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-medium-italic-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-medium-italic-pro.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-bold-pro.eot');
  src: url('./mabry-mono/mabry-mono-bold-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-bold-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-bold-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-bold-pro.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MabryMono';
  src: url('./mabry-mono/mabry-mono-bold-italic-pro.eot');
  src: url('./mabry-mono/mabry-mono-bold-italic-pro.eot?#iefix') format('embedded-opentype'),
    url('./mabry-mono/mabry-mono-bold-italic-pro.woff2') format('woff2'),
    url('./mabry-mono/mabry-mono-bold-italic-pro.woff') format('woff'),
    url('./mabry-mono/mabry-mono-bold-italic-pro.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}


/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * guesthouseshop.com
 *
 */
@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Thin.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-ThinItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Light.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-LightItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Regular.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Italic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Book.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-BookItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-BookItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Medium.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-MediumItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-MediumItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Bold.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-BoldItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-Black.woff2') format('woff2'),
    url('./circular/CircularXXWeb-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-BlackItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-ExtraBlack.woff2') format('woff2'),
    url('./circular/CircularXXWeb-ExtraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  src: url('./circular/CircularXXWeb-ExtraBlackItalic.woff2') format('woff2'),
    url('./circular/CircularXXWeb-ExtraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
