@import 'swiper/css';
@import 'swiper/css/virtual';

@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

.swiper {
  overflow: visible;
}

.swiper-container {
  overflow: visible;

  @media (min-width: 960px) {
    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        display: flex;
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 10px;

  &:after {
    color: black;
    font-size: 15px;
  }
}

.swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.swiper-pagination {
  top: auto !important;
  bottom: -15px !important;
}

.swiper-pagination-progressbar {
  height: 2px !important;
}

.swiper-pagination-progressbar-fill {
  background: #080808 !important;
}
