$hamburger-padding-x: 10px;
$hamburger-padding-y: 10px;
$hamburger-layer-height : 2px;
$hamburger-layer-width : 20px;
$hamburger-layer-spacing : 4px;

@import 'hamburgers/_sass/hamburgers/hamburgers.scss';

.hamburger {
  outline: 0;
  position: relative;
  top: 2px;
}

.hamburger-box {
    *,
    *:before,
    *:after {
      transition: all .3s !important;
    }
  }
