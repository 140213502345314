body {
    transition: background-color .3s;
    background-color: #f7f7f7;
    border-color: transparent;
}

#__next {
    background-color: inherit;
    border-color: inherit;
}

html {
    scroll-padding-top: 100px;
}

.optimize-anti-flicker {
    opacity: 0 !important;
}
